import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.hideBar()
    this.setupTurboListeners()
  }

  setupTurboListeners() {
    document.addEventListener("turbo:before-visit", this.showBar.bind(this))
    document.addEventListener("turbo:load", this.hideBar.bind(this))
  }

  showBar() {
    this.element.classList.remove("hidden")
  }

  hideBar() {
    this.element.classList.add("hidden")
  }
}